import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// If we end up handling more data and routes, we could implement the weldeye moduleproviders pattern
const routes: Routes = [
  {
    path: 'mastertig',
    loadChildren: () => import('./modules/tig/tig.module').then(m => m.TigModule)
  },
  {
    path: 'fastmig',
    loadChildren: () => import('./modules/mig/mig.module').then(m => m.MigModule)
  },
  {
    path: 'masterm',
    loadChildren: () => import('./modules/mig/mig.module').then(m => m.MigModule)
  }
];


export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
});
