import { Component } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private _sanitizer: DomSanitizer, private router: Router) {
    this.selectedDevice = window.location.pathname.replace('/', '');
  }
  selectedDevice = '';
  mainBg: SafeStyle =
    this._sanitizer.bypassSecurityTrustStyle('url("assets/images/ceci_web_bgr.png") 0 0 / contain no-repeat, black');

  selectDevice(device: string) {
    this.selectedDevice = device;
    this.router.navigate([`/${this.selectedDevice}`]);
  }

}
