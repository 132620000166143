 <!-- Background of the app is created with 2 separate images-->
 <div [style.background]="mainBg" class='main-background left'></div>
 <div [style.background]="mainBg" class='main-background right'></div>

<!-- Header with kemppi logo etc.-->
<header fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1rem">
  <img id="kemppi-logo" src="assets/images/Kemppi_logo_negative_transparent.png" alt="Kemppi Logo">
  <p class="subtitle">Create screensaver images</p>
  <p [class.hidden]="selectedDevice !== ''" class="title">Select your device</p>
  <img
    id="fastmig" class="device-logo" [class.faded]="selectedDevice && selectedDevice !== 'fastmig'"
    (click)="selectDevice('fastmig')" src="assets/images/fastmig_logo.png" alt="FastMig Logo"
  >
  <img
    id="masterm" class="device-logo bigger" [class.faded]="selectedDevice && selectedDevice !== 'masterm'"
    (click)="selectDevice('masterm')" src="assets/images/masterm_logo.png" alt="MasterM Logo"
  >
  <img
    id="mastertig" class="device-logo" [class.faded]="selectedDevice && selectedDevice !== 'mastertig'"
    (click)="selectDevice('mastertig')" src="assets/images/ceci_web_mastertig_logo.png" alt="MasterTig Logo"
  >
  <div class="panels">
    <img class="panel" [class.mig-transition]="selectedDevice && selectedDevice !== 'mastertig'" [class.hide]="selectedDevice && selectedDevice === 'mastertig'" src="assets/images/panel/new_panel.png">
    <img class="panel" [class.tig-transition]="selectedDevice === 'mastertig'" [class.hide]="selectedDevice && selectedDevice !== 'mastertig'" src="assets/images/panel/ceci_web_device.png">
  </div>
</header>
<div class="container" fxLayout="column" fxLayoutAlign="center center">
  <router-outlet></router-outlet>
</div>


